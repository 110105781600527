export const toEnDigit = (s?: string) => {
  return s?.replace(
    /[\u0660-\u0669\u06f0-\u06f9]/g, // Detect all Persian/Arabic Digit in range of their Unicode with a global RegEx character set
    // @ts-ignore
    function (a) {
      // eslint-disable-next-line no-bitwise
      return a?.charCodeAt(0) & 0xf;
    } // Remove the Unicode base(2) range that not match
  );
};

export const toArabicDigit = (s?: string) => {
  return s?.replace(/\d/g, (digit) => {
    const arabicDigits = "٠١٢٣٤٥٦٧٨٩";
    const englishDigit = digit.charCodeAt(0) - "0".charCodeAt(0);
    return arabicDigits[englishDigit];
  });
};

export const validatePhoneNumber = (phoneNumber: string) => {
  // const phoneNumberValidation = /^01[0125][0-9]{8}$/.test(
  //   toEnDigit(phoneNumber.toString())
  // );
  // return phoneNumberValidation;
  return true;
};
