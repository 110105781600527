import React, { useEffect } from "react";
import AuthTopTabs from "../Components/AuthTopTabs";
import CustomButton from "../Components/CustomButton";
import PhoneInput from "../Components/PhoneInput";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { usePersistFormikValues } from "formik-persist-values";
import { toEnDigit, validatePhoneNumber } from "../Helpers/helperFunctions";
import { _login } from "../Helpers/api.service";
import { useStore } from "../Helpers/store";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const loggedIn = useStore((state) => state.loggedIn);

  const validationSchema = Yup.object().shape({
    MobileNo: Yup.string().required("الرجاء ادخال رقم الهاتف"),
  });

  useEffect(() => {
    if (loggedIn) navigate("/", { replace: true });
  }, [loggedIn, navigate]);

  const formik = useFormik({
    initialValues: {
      MobileNo: "",
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      const inputs = {
        MobileNo: submittedValues.MobileNo.toString(),
      };
      setError("");

      if (!validatePhoneNumber(submittedValues.MobileNo)) {
        setFieldError("MobileNo", "رقم الهاتف غير صالح");
        return;
      }

      const data = await _login(inputs);

      if (data.Result) {
        navigate("/otp/login");
      } else {
        setError(data.Error);
      }
    },
  });

  usePersistFormikValues(formik, {
    name: "login-temp",
  });

  const { values, errors, setFieldValue, handleSubmit, setFieldError } = formik;

  const handlePhoneNumberChange = (value: string) => {
    const convToEn = toEnDigit(value);
    setFieldValue("MobileNo", convToEn);
  };

  return (
    <div
      className="container flex justify-between flex-col h-[93vh] w-full ml-[auto] mr-[auto]"
      dir={"rtl"}
    >
      <div className="mt-5">
        <AuthTopTabs title={"تسجيل الدخول"} />
        <div className="h-[49px]" />

        <PhoneInput
          title={"رقم الهاتف"}
          errorMessage={errors.MobileNo}
          value={values.MobileNo}
          onChange={handlePhoneNumberChange}
          fullSize
        />
        <span className="text-[14px] text-black-500">
          سيتم إرسال رسالة نصية تحتوي على كود للتحقق من الرقم
        </span>
      </div>

      <div className="flex flex-col">
        <span className="text-[15px] font-semibold text-red-200 mb-5 text-center">
          {error || ""}
        </span>
        <div className="flex mb-10">
          <CustomButton onClick={() => handleSubmit()}>
            <span>التالي</span>
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default Login;
