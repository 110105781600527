import React, { useEffect, useRef, useState } from "react";
import CustomSwiper from "../Components/Swiper";
import CompaniesList from "../Components/CompaniesList";
import useModal from "../hooks/useModal";
import HomeLoggedInButtons from "../Components/HomeLoggedInButtons";
import MerchantBranchesModal from "../Components/MerchantBranchesModal";
import RechargeModal from "../Components/RechargeModal";
import RedeemModal from "../Components/RedeemModal";
import HomeAuthButtons from "../Components/HomeAuthButtons";
import { useStore } from "../Helpers/store";
import AddCodeModal from "../Components/AddCodeModal";
import {
  _getAllMerchantBranches,
  _getBalance,
  _getTransactionsHistory,
} from "../Helpers/api.service";
import ContactButton from "../Components/ContactButton";
import TransactionList from "../Components/TransactionList";

_getAllMerchantBranches();
_getTransactionsHistory();

function Home() {
  const merchantBranchesModal = useModal();
  const rechargeModal = useModal();
  const redeemModal = useModal();
  const addCodeModal = useModal();
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const loggedIn = useStore((state) => state.loggedIn);
  const Logout = useStore((state) => state.Logout);

  useEffect(() => {
    setHeight(ref.current.clientHeight / 2);
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) _getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div dir="rtl" className={"w-full mb-[25px]"}>
      <div className="w-full relative">
        {/* mask */}
        <div className="absolute top-0 right-0 left-0 bottom-0 bg-[rgba(0,0,0,0.5)]"></div>
        <div
          ref={ref}
          className={`min-w-[300px] w-[80vw] max-w-[80%] rounded-[10px] bg-[#fff] shadow-xl absolute right-[50%] translate-x-[50%]`}
          style={{ bottom: `-${!loggedIn ? height : height + 60}px` }}
        >
          {!loggedIn ? (
            <HomeAuthButtons />
          ) : (
            <HomeLoggedInButtons
              onRecharge={rechargeModal.openModal}
              onAddCode={addCodeModal.openModal}
              onRedeem={redeemModal.openModal}
            />
          )}
        </div>
        <img src="images/banner.jpg" alt="" className="w-full" />

        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] text-center">
          <p className="text-[26px] font-bold text-white">
            أدخل الرمز واربح جائزتك
          </p>
        </div>
      </div>

      {/* Swiper */}
      <div
        className="w-[90%] mx-auto"
        style={{ marginTop: `${!loggedIn ? height + 25 : height + 85}px` }}
      >
        <CustomSwiper />
      </div>
      {/* End Swiper */}

      {/* Companies */}
      <CompaniesList modalConfig={merchantBranchesModal} />
      {/* End  Companies */}

      {/* Scratch and win */}
      <div className="flex flex-col justify-center items-center mt-[25px] px-[16px]">
        <img src="images/scratchAndWin.png" className="w-full" alt="" />
        <div
          className="text-right mt-[5px]"
          onDoubleClick={() => {
            Logout();
          }}
        >
          <p>
            خربش أكتر وأجمع أكواد أكتر وحول رصيدك لكوبونات من محلاتك المفضلة او
            أشحن رصيد لنفسك أو لحبايبك
          </p>
        </div>
      </div>
      {/* End Scratch and win */}

      {/* Transaction */}
      <TransactionList />
      {/* End Transaction */}

      <MerchantBranchesModal modalConfig={merchantBranchesModal} />
      <RechargeModal modalConfig={rechargeModal} />
      <RedeemModal modalConfig={redeemModal} />
      <AddCodeModal modalConfig={addCodeModal} />

      <ContactButton />
    </div>
  );
}

export default Home;
