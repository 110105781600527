import React, { useEffect } from "react";
import { useGeolocated } from "react-geolocated";
import GoogleMapReact from "google-map-react";
import { GOOGLE_API_KEY } from "../Helpers/api.service";
import pin from "../Assets/pin.png";

const Marker = () => {
  return <img src={pin} alt="marker pin" className="absolute z-50 w-[36px]" />;
};

interface MapProps {
  onChange: (location: { lat: string; lng: string; address: string }) => void;
}

const Map: React.FC<MapProps> = ({ onChange }) => {
  const { coords, getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    if (!coords) getPosition();
    onChange({
      lat: coords?.latitude.toString(),
      lng: coords?.longitude.toString(),
      address: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  return (
    <div className="h-full w-full flex-col justify-center items-center flex">
      <Marker />
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        options={{
          fullscreenControl: false,
        }}
        center={
          coords && {
            lat: coords?.latitude,
            lng: coords?.longitude,
          }
        }
        defaultCenter={{
          lat: coords?.latitude || 30.0690903,
          lng: coords?.longitude || 31.2191743,
        }}
        debounced
        defaultZoom={16}
        onDragEnd={async (map) => {
          const center = map.getCenter();
          onChange({
            lat: center.lat(),
            lng: center.lng(),
            address: "",
            // address: await _getAddress(center.lat(), center.lng()),
          });
        }}
      ></GoogleMapReact>
    </div>
  );
};

export default Map;
