import React, { useEffect } from "react";
import AuthTopTabs from "../Components/AuthTopTabs";
import CustomButton from "../Components/CustomButton";
import SignupForm from "../Components/SignupForm";
import MapTab from "../Components/MapTab";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { usePersistFormikValues } from "formik-persist-values";
import { validatePhoneNumber } from "../Helpers/helperFunctions";
import { _register } from "../Helpers/api.service";
import { useStore } from "../Helpers/store";

function Signup() {
  const navigate = useNavigate();
  const [tab, setTab] = React.useState("info");
  const [error, setError] = React.useState("");
  const loggedIn = useStore((state) => state.loggedIn);

  useEffect(() => {
    if (loggedIn) navigate("/", { replace: true });
  }, [loggedIn, navigate]);

  const changeTab = (val: string) => () => {
    if (!values.FirstName || !values.LastName) return;

    if (Object.keys(errors).length > 0) return;

    if (!validatePhoneNumber(values.MobileNo)) {
      setFieldError("MobileNo", "رقم الهاتف غير صالح");
      return;
    }

    tab === "info" ? setTab(val) : handleSubmit();
  };

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("الرجاء ادخال الاسم الاول"),
    LastName: Yup.string().required("الرجاء ادخال الاسم الاخير"),
    MobileNo: Yup.string().required("الرجاء ادخال رقم الهاتف"),
    ShopName: Yup.string().required("الرجاء ادخال اسم المتجر"),
    City: Yup.string().required("الرجاء ادخال المدينة"),
    Region: Yup.string().required("الرجاء ادخال المنطقة"),
  });

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Latitude: "",
      Longitude: "",
      MobileNo: "",
      ShopName: "",
      City: "",
      Region: "",
      ChkCalls: "",
      ChkNews: "",
      ChkSMS: "",
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      const inputs = {
        ...submittedValues,
        Latitude: submittedValues.Latitude.toString(),
        Longitude: submittedValues.Longitude.toString(),
        MobileNo: submittedValues.MobileNo.toString(),
        ChkCalls: submittedValues.ChkCalls.toString(),
        ChkNews: submittedValues.ChkNews.toString(),
        ChkSMS: submittedValues.ChkSMS.toString(),
      };

      setError("");

      if (!submittedValues.Latitude || !submittedValues.Longitude) {
        setFieldError("Latitude", "الرجاء ادخال الموقع");
        return;
      }

      const data = await _register(inputs);

      if (data.Result) {
        navigate("/otp/signup");
      } else {
        setError(data.Error);
      }
    },
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
  } = formik;

  usePersistFormikValues(formik, {
    name: "signup-temp",
    ignoreValues: ["Latitude", "Longitude"],
  });

  return (
    <div
      className="container flex items-center justify-between flex-col h-[93vh] ml-[auto] mr-[auto]"
      dir={"rtl"}
    >
      <div
        className={
          tab === "info"
            ? "mt-5"
            : "mt-5 flex flex-col justify-center items-center"
        }
      >
        <div className={tab === "info" ? "" : "flex w-[91.4%]"}>
          <AuthTopTabs title={"أبدا الان"} withTabs active={tab} />
        </div>
        {tab === "info" && (
          <SignupForm
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            values={values}
            // @ts-ignores
            errors={errors}
            // @ts-ignores
            touched={touched}
          />
        )}

        {tab === "location" && (
          <MapTab
            setFieldValue={setFieldValue}
            errorMessage={errors.Latitude}
          />
        )}
      </div>

      <span className="text-[15px] font-semibold text-red-200 mt-5 text-center">
        {error || ""}
      </span>
      <div className={tab === "info" ? "flex w-[100%]" : "flex w-[91.4%]"}>
        <CustomButton onClick={changeTab("location")}>
          <span>التالي</span>
        </CustomButton>
      </div>
    </div>
  );
}

export default Signup;
