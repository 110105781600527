import React, { useEffect } from "react";
import { useStore } from "../../Helpers/store";
import { toArabicDigit } from "../../Helpers/helperFunctions";

type Props = {
  onChange?: (value: number) => void;
};

type Price = {
  value: number;
  selected: boolean;
};

const availablePrices: Price[] = [
  {
    value: 50,
    selected: false,
  },

  {
    value: 250,
    selected: false,
  },
  {
    value: 500,
    selected: false,
  },
];

const PriceItem = ({
  item,
  onClick,
  disabled,
}: {
  item: Price;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={`w-[100%] h-[50px] bg-white rounded-[10px] flex flex-col justify-center items-start ${
        item.selected ? "border-red-100" : "border-gray-500"
      } border-[1px] px-[20px] ${disabled && "text-gray-600"}`}
    >
      <div className="flex flex-row items-center ">
        <div className="w-[15px] h-[15px] rounded-full border flex justify-center items-center ml-[9px] mb-1">
          <div
            className={`w-[11px] h-[11px] rounded-full ${
              item.selected ? "bg-red-100" : "bg-white"
            }`}
          />
        </div>
        <span className="text-[20px] font-[500]">
          {toArabicDigit(String(item.value) + " جم")}
        </span>
      </div>
    </div>
  );
};

const SelectPrice = (props: Props) => {
  const [prices, setPrices] = React.useState(availablePrices);
  const balance = useStore((state) => state.Balance);

  const handlePriceClick = (index: number) => {
    setPrices((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            selected: true,
          };
        } else {
          return {
            ...item,
            selected: false,
          };
        }
      });
    });
  };

  useEffect(() => {
    props.onChange?.(prices.find((item) => item.selected)?.value || 0);
  }, [prices, props]);

  return (
    <div className=" grid grid-cols-2 gap-[10px]">
      {prices.map((item, index) => {
        return (
          <PriceItem
            key={index}
            item={item}
            disabled={Number(balance) < item.value}
            onClick={() =>
              !(Number(balance) < item.value) && handlePriceClick(index)
            }
          />
        );
      })}
    </div>
  );
};

export default SelectPrice;
