import React from "react";
import { ModalConfig } from "../../hooks/useModal";
import { useStore } from "../../Helpers/store";

type Props = {
  modalConfig?: ModalConfig;
};

const companies = [
  {
    merchant: "zaman",
    logo: "images/zaman.png",
  },
  {
    merchant: "metro",
    logo: "images/metro.png",
  },
  {
    merchant: "kazyon",
    logo: "images/kazyon.png",
  },
  {
    merchant: "btech",
    logo: "images/btech.png",
  },
];

const CompaniesList = (props: Props) => {
  const { modalConfig } = props;
  const SetSelectedMerchant = useStore((state) => state.SetSelectedMerchant);

  return (
    <div className="flex flex-row justify-center items-center gap-[32px] mt-[25px]">
      {companies.map((item, index) => {
        return (
          <div
            key={index}
            className="w-[15%] h-[auto] bg-white rounded-[10px] flex flex-col justify-center items-center"
            onClick={() => {
              SetSelectedMerchant(item.merchant);
              modalConfig?.openModal();
            }}
          >
            <img
              src={item.logo}
              alt=""
              className="w-[100%] h-[auto] object-contain"
            />
          </div>
        );
      })}
    </div>
  );
};

export default CompaniesList;
