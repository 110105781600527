import React from "react";
import Map from "../Map";

type Props = {
  setFieldValue: (name: string, value: string | boolean) => void;
  errorMessage?: string;
};

export default function MapTab({ setFieldValue, errorMessage }: Props) {
  const [address, setAddress] = React.useState("");

  return (
    <div className="w-[100vw] h-[70vh] mt-[17px]">
      <Map
        onChange={(location) => {
          setFieldValue("Latitude", location.lat);
          setFieldValue("Longitude", location.lng);
          setAddress(location.address);
        }}
      />
      <span className="text-[12px] font-semibold text-red-200  mt-2">
        {errorMessage || address}
      </span>
    </div>
  );
}
