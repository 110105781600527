import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  title: string;
  withTabs?: boolean;
  active?: string;
};

export default function AuthTopTabs({ title, withTabs, active }: Props) {
  const navigator = useNavigate();
  return (
    <div className="flex items-center justify-center flex-col w-full">
      <div
        onClick={() => {
          navigator(-1);
        }}
        className="text-[14px] font-semibold self-start"
      >
        → عودة
      </div>
      <span className="text-[14px] font-semibold">{title}</span>

      {withTabs && (
        <div className="flex items-center justify-between w-full mt-5">
          <div
            className={`border-b-2 ${"border-red-200"}  w-full flex items-center justify-center`}
          >
            <span className="text-[15px] font-semibold text-black-300">
              {"معلومات شخصية"}
            </span>
          </div>
          <div className="w-5" />
          <div
            className={`border-b-2 ${
              active === "location" ? "border-red-200" : "border-gray-400"
            } w-full flex items-center justify-center`}
          >
            <span
              className={`text-[15px] font-semibold  ${
                active ? "text-black-300" : "text-gray-400"
              }`}
            >
              {"الموقع"}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
