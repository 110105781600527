import React from "react";
import MerchantBranchItem from "../MerchantBranchItem";
import { useStore } from "../../Helpers/store";

type Props = {};

const MerchantBranches = (props: Props) => {
  const SelectedMerchant = useStore((state) => state.SelectedMerchant);
  const MerchantBranches = useStore((state) => state.MerchantBranches);

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <img
          src={`images/${SelectedMerchant}.png`}
          className="w-[73px] h-[auto]"
          alt=""
        />
        {/* <p className="mt-[4px] text-[26px] font-[500]">تريدلاين ستورز</p> */}
      </div>

      <div className="flex flex-col gap-[12px] mt-[14px]">
        {(
          MerchantBranches.filter(
            (item) => item.merchant === SelectedMerchant
          )[0]?.branches || []
        ).map((item, index, array) => (
          <React.Fragment key={index}>
            <MerchantBranchItem branch={item} />
            {index < array.length - 1 && (
              <div className="w-full h-[1px] bg-gray-400" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MerchantBranches;
