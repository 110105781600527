import React from "react";

import success from "../../Assets/tick-circle.png";
import warning from "../../Assets/warning.png";
import error from "../../Assets/error-icon.png";

type Props = {
  state: "success" | "error" | "warning";
  title?: string;
  body?: string;
};

const mapStateToImage = {
  success,
  error,
  warning,
};

const ModalState = (props: Props) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <img
        className="w-[60px] h-[60px] object-contain"
        src={mapStateToImage[props.state]}
        alt=""
      />
      <div className="flex flex-col items-center mt-[20px] ">
        {props?.title ? <p className="text-[26px]">{props.title}</p> : null}
        {props?.body ? (
          <p className="text-[14px] text-gray-600">{props.body}</p>
        ) : null}
      </div>
    </div>
  );
};

export default ModalState;
