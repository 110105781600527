import React, { useCallback, useEffect, useState } from "react";
import AuthTopTabs from "../Components/AuthTopTabs";
import CustomButton from "../Components/CustomButton";
import OTPInputs from "../Components/OTPInputs";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../Helpers/store";
import { toArabicDigit } from "../Helpers/helperFunctions";
import { _resendCode, _validateOTP } from "../Helpers/api.service";

function OTP() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = React.useState("");

  const loggedIn = useStore((state) => state.loggedIn);
  const phoneNumber = useStore((state) => state.MobileNo);
  const [timeLeft, setTimeLeft] = useState(60);

  const [otp, setOTP] = useState<number[]>([]);

  useEffect(() => {
    if (loggedIn) navigate("/", { replace: true });
  }, [loggedIn, navigate]);

  const handleOtp = (value: string, index: number) => {
    if (!value) {
      const newOTP = [...otp];
      // @ts-ignore
      newOTP[index] = undefined;
      setOTP(newOTP);
    } else {
      const newValue = +value;
      if (!isNaN(newValue)) {
        const newOTP = [...otp];
        newOTP[index] = newValue;
        setOTP(newOTP);
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const code = event.clipboardData.getData("text");
    setOTP(code.split("").map((digit) => +digit));
  };

  const handleSubmit = useCallback(async () => {
    setError("");
    const data = await _validateOTP({
      MobileNo: phoneNumber,
      OTP: otp.join(""),
      isLogin: location.pathname.includes("login"),
    });

    if (data.Result) {
      navigate("/", { replace: true });
    } else {
      setError(data.Error);
    }
  }, [location.pathname, navigate, otp, phoneNumber]);

  const resendCode = () => {
    setError("");
    _resendCode();
    setTimeLeft(60);
  };

  useEffect(() => {
    if (otp.join("").length === 4) {
      handleSubmit();
    }
  }, [handleSubmit, otp]);

  useEffect(() => {
    setTimeout(() => {
      if (timeLeft !== 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    if (timeLeft === 0) {
      setTimeLeft(0);
    }
  }, [timeLeft]);

  return (
    <div
      className="container flex justify-between flex-col h-[93vh] ml-[auto] mr-[auto]"
      dir={"rtl"}
    >
      <div className="mt-5">
        <AuthTopTabs title={"تسجيل الدخول"} />
        <div className="h-[49px]" />
        <div className="text-[15px] text-black-500 text-center">
          تم إرسال رسالة نصية تحتوي على كود إلى الرقم
        </div>
        <div className="text-center text-[18px] text-black-500">
          {toArabicDigit(phoneNumber) || ""}{" "}
          <span
            className=" mr-4 text-center text-[15px] text-black-500 font-semibold underline"
            onClick={() => navigate(-1)}
          >
            تغيير الرقم؟
          </span>
        </div>
        <div className="h-[30px]" />
        <OTPInputs handleOtp={handleOtp} handlePaste={handlePaste} otp={otp} />
        <div className="flex justify-center">
          {timeLeft > 0 ? (
            <div className="text-center text-[12px] bg-gray-700 px-[5px] pt-[5px] pb-[2px] rounded-full mt-[25px] w-[164px]">
              إعادةإرسال الكود خلال {"٠٠:" + toArabicDigit(timeLeft.toString())}
            </div>
          ) : (
            <div
              onClick={resendCode}
              className="text-center text-[12px] bg-gray-700 px-[5px] pt-[5px] pb-[2px] rounded-full mt-[25px] w-[164px]"
            >
              أعادة أرسال الكود
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col">
        <span className="text-[15px] font-semibold text-red-200 mb-5 text-center">
          {error === "Invalid OTP" ? "رمز التحقق خاطئ" : error || ""}
        </span>
        <div className="flex mb-10">
          <CustomButton onClick={handleSubmit}>
            <span>التالي</span>
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default OTP;
