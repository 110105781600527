import React from "react";
import AuthTopTabs from "../Components/AuthTopTabs";

const Terms = () => {
  return (
    <div
      className="container flex flex-col h-[93vh] ml-[auto] mr-[auto] w-full"
      dir={"rtl"}
    >
      <div className="mt-5 mb-10">
        <AuthTopTabs title={"الشروط و الاحكام"} />
      </div>
      <div className="my-1">
        <p className="font-semibold text-sm">عرض برينجلز مصر للتجار</p>
        <p className="text-xs mt-1">تاريخ البداية: 1\نوفمبر\2023</p>
        <p className="text-xs">تاريخ النهاية: 29\فبراير\2024</p>

        <ul className="list-disc pr-3 mt-3">
          <li className="font-normal text-xs mt-1">
            قم بخدش الملصق المطبوع على كل كرتونة ثم قم بأرسال الكود الذي تم خدشة
            في رسالة نصية الي رقم 2653
          </li>
          <li className="font-normal text-xs mt-1">
            يقوم التاجر بإدخال البيانات المطلوبة للاشتراك في العرض
          </li>
          <li className="font-normal text-xs mt-1">
            يمكن للتجار إدخال العديد من الاكواد من خلال الرابط بعد التسجيل
          </li>

          <li className="font-normal text-xs mt-1">
            يحق للتجار المشاركة عدة مرات لزيادة الرصيد، ولكن يلزم إدخال كود مميز
            كل مرة
          </li>
          <li className="font-normal text-xs mt-1">
            للاستفادة من العرض يجب على كل تاجر إدخال البيانات المطلوبة بشكل صحيح
          </li>
          <li className="font-normal text-xs mt-1">
            يمكن التجار الفائزين بالقسائم الشرائية إستبدالها من المتاجر
            المشاركين في العرض كما هو موضح على الرابط
          </li>
          <li className="font-normal text-xs mt-1">
            يقر ويوافق التاجر الفائز بالجوائز على إخلاء مسؤولية برينجلز مصر
            وموظفيها من أي ضرر مباشر أو غير مباشر مترتب على أو مرتبط باستخدام
            الجوائز من قبل الفائزون.
          </li>
          <li className="font-normal text-xs mt-1">
            الجوائز الممنوحة في هذا العرض لا يمكن إستبدالها نقداً
          </li>
          <li className="font-normal text-xs mt-1">
            بالمشاركة في هذا العرض، يوافق التجار المشاركون على الالتزام بهذه
            الشروط والأحكام
          </li>
          <li className="font-normal text-xs mt-1">
            تحتفظ برينجلز مصر بالحق في إلغاء أو تعديل قيمة هذا العرض في أي وقت
            خلال مدة العرض دون سابق إنذار في حالة القوة القاهرة أو الظروف
            الطارئة أو أي حدث آخر خارج عن سيطرة برينجلز مصر.
          </li>
          <li className="font-normal text-xs mt-1">
            تخضع هذه الشروط والأحكام لقوانين جمهورية مصر العربية وأي نزاعات
            ستخضع للاختصاص القضائي الحصري لمحاكم جمهورية مصر العربية
          </li>
          <li className="font-normal text-xs mt-1">
            من خلال المشاركة في العرض، يقر التجار المشاركون بأنهم قد قرأوا
            وفهموا هذه الشروط والأحكام ويوافقون على الالتزام بها.
          </li>
        </ul>
      </div>
      <div className="my-1 text-xs">
        <p className="font-normal text-xs">
          السادة الأفاضل يسعدنا مساعدتكم واستقبال مكالمتكم على مدار اليوم من
          الساعة ۹ صباحا حتى ال١٢ صباحا على الرقم التالي:
        </p>
        <div className="flex flex-col mt-2">
          <a href={`tel:01228000020`} className="font-normal text-xs">
            01228000020
          </a>
        </div>
      </div>
    </div>
  );
};

export default Terms;
