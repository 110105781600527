import React from "react";
import CustomDialogue from "../CustomDialogue";
import MerchantBranches from "../MerchantBranches";
import { ModalConfig } from "../../hooks/useModal";

type Props = {
  modalConfig: ModalConfig;
};

const MerchantBranchesModal = ({ modalConfig }: Props) => {
  return (
    <CustomDialogue modalConfig={modalConfig}>
      <MerchantBranches />
    </CustomDialogue>
  );
};

export default MerchantBranchesModal;
