import React from "react";
import CustomButton from "../CustomButton";
import CustomInput from "../CustomInput";
import { _addCodes } from "../../Helpers/api.service";
import ModalState from "../ModalState";
import { toArabicDigit } from "../../Helpers/helperFunctions";

const AddCode = () => {
  const [inputs, setInputs] = React.useState([{ value: "", error: false }]);
  const [requestResult, setRequestResult] = React.useState({
    blocked: false,
    warning: false,
    submitted: false,
    validCodesCount: 0,
  });

  const invalidInputs = inputs.filter((input) => input.error).length;

  const handleInputChange = (value: string, index: number) => {
    const updatedInputs = inputs.map((input, i) =>
      i === index ? { ...input, value: value, error: false } : input
    );
    setInputs(updatedInputs);
  };

  const addMoreInputs = () => {
    if (inputs.length < 20) {
      setInputs([...inputs, { value: "", error: false }]);
    }
  };

  const removeInput = (index: number) => {
    setInputs(inputs.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    _addCodes(inputs.map((input) => input.value)).then((res) => {
      setRequestResult({
        blocked: res.blocked,
        warning: res.warning,
        submitted: true,
        validCodesCount: res.validCodesCount,
      });

      if (res?.invalidCodes.length > 0) {
        setInputs(res.invalidCodes);
      } else {
        setInputs((prev) => {
          return prev.filter((_, index) =>
            res.validCodesIndexes.includes(index)
          );
        });
      }
    });
  };

  if (
    requestResult.submitted &&
    !requestResult.blocked &&
    !requestResult.warning &&
    invalidInputs === 0
  ) {
    return <ModalState state="success" title="تم اضافة الأكواد بنجاح" />;
  }

  if (requestResult.submitted && requestResult.blocked) {
    return (
      <ModalState
        state="error"
        title="تم اضافة الكود خطأ"
        body="لقد أدخلت الرمز بشكل خاطئ
    ، وتم حظرك لمدة 24 ساعة"
      />
    );
  }

  if (requestResult.submitted && requestResult.warning) {
    return (
      <ModalState
        state="warning"
        title="تم اضافة الكود خطأ"
        body="سيتم حظرك لمدة 24 ساعة اذا أضفت اكواد اخري خطأ "
      />
    );
  }

  const disabled = inputs.filter((input) => input.value.length === 0).length;

  return (
    <div className="w-full">
      {requestResult.validCodesCount > 0 ? (
        <ModalState
          state="success"
          title={`تم اضافة ${toArabicDigit(
            requestResult?.validCodesCount?.toString()
          )} أكواد بنجاح`}
        />
      ) : (
        <div className="flex justify-center items-end absolute top-[15px] gap-[5px]">
          <p className="text-[26px] font-[400]">أضف الكود</p>
          <p className="text-[14px] font-[400]">(الحد الأقصى 20 للمرة)</p>
        </div>
      )}

      <div className="flex flex-col mt-[24px]">
        {invalidInputs ? (
          <p className="text-[14px] text-red-100 font-[400]">
            *يوجد عدد {toArabicDigit(invalidInputs?.toString())} كود خطأ
          </p>
        ) : null}
        {inputs.map((input, index) => (
          <CustomInput
            title="الكود"
            key={index}
            fullSize
            value={input.value}
            onChange={(value) => handleInputChange(value, index)}
            placeholder="0000 0000"
            errorBorder={input.error}
            withRemoveIcon={index !== 0}
            onRemoveIconClick={() => removeInput(index)}
          />
        ))}

        <div className="flex gap-[10px] mt-[14px]">
          <CustomButton onClick={handleSubmit} disabled={disabled > 0}>
            تأكيد
          </CustomButton>
          <CustomButton
            outline
            onClick={addMoreInputs}
            disabled={inputs.length === 20}
          >
            أضف اكواد أكثر
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default AddCode;
