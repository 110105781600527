import React from "react";
import TickCircle from "../../Assets/tick-circle.png";
import CustomButton from "../CustomButton";
import copy from "copy-text-to-clipboard";
import { _copyToClipboard } from "../../Helpers/api.service";

type Props = {
  value: string;
};

const RedeemSuccess = (props: Props) => {
  const [copied, setCopied] = React.useState(false);
  return (
    <div className="flex flex-col">
      <div className="mb-[20px] flex justify-center">
        <img
          src={TickCircle}
          className="h-[72px] w-[72px] object-contain"
          alt=""
        />
      </div>
      <p className="text-[26px] font-[500] text-center">
        لقد قمت بإنشاء القسيمة بنجاح
      </p>
      <div className="mt-[18px] mb-[14px] flex justify-center items-center bg-lightBlue-100 rounded-[5px] min-h-[3.125rem]">
        <p className="text-[24px] font-[500]">{props.value}</p>
      </div>

      <div className="flex">
        <CustomButton
          disabled={copied}
          onClick={() => {
            copy(props.value);
            _copyToClipboard(props.value);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
        >
          {copied ? "تم نسخ الرمز بنجاح" : "نسخ الرمز"}
        </CustomButton>
      </div>
    </div>
  );
};

export default RedeemSuccess;
