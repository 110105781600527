import React from "react";
import { useStore } from "../../Helpers/store";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  outline?: boolean;
};
const CustomButton = (props: Props) => {
  const isLoading = useStore((state) => state.isLoading);
  const { outline, ...rest } = props;

  return (
    <button
      className={`flex flex-1 h-[58px] disabled:bg-gray-500 disabled:border-gray-500 disabled:text-white ${
        props?.outline
          ? "bg-background border-red-100 border-[1px] text-black"
          : "bg-red-100 border-[1px] text-white "
      } font-bold  items-center justify-center rounded-[12px] border-red-100 border-[1px] border-solid`}
      {...rest}
      disabled={rest.disabled || isLoading}
    />
  );
};

export default CustomButton;
