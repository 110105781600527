import React from "react";
import { _openWithGoogleMaps } from "../../Helpers/api.service";

type Props = {
  branch: {
    BranchAddress: string;
    BranchId: string;
    BranchName: string;
    CityName: string;
    Latitude: string;
    LogoImage: string;
    Longitude: string;
  };
};

const BranchButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="flex flex-1 py-[10px] px-[15px] text-[12px] bg-red-100 font-bold  text-white items-center justify-center rounded-[20px]"
      {...props}
    />
  );
};

const MerchantBranchItem = ({ branch }: Props) => {
  return (
    <div className="flex justify-between items-center">
      <div className="w-[50%]">
        <p className="text-[14px] font-[600]">{branch.CityName}</p>
        <p className="text-[10px]">{branch.BranchAddress}</p>
      </div>

      <div>
        <BranchButton
          onClick={() => _openWithGoogleMaps(branch.Latitude, branch.Longitude)}
        >
          أظهر علي الخريطة
        </BranchButton>
      </div>
    </div>
  );
};

export default MerchantBranchItem;
