import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "./styles.css";
import CloseIcon from "../../Assets/close@3x.png";
import { ModalConfig } from "../../hooks/useModal";

type Props = {
  children: React.ReactNode;
  modalConfig: ModalConfig;
  onOpenChange?: () => void;
};

const CustomDialogue = (props: Props) => {
  const { modalConfig } = props;
  return (
    <Dialog.Root open={modalConfig.open} onOpenChange={props?.onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay
          onClick={modalConfig.closeModal}
          className="DialogOverlay"
        />
        <Dialog.Content dir="rtl" className="DialogContent">
          <div className="flex w-full justify-end">
            <div onClick={modalConfig.closeModal}>
              <img src={CloseIcon} alt="" className="w-[30px]" />
            </div>
          </div>
          {props.children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CustomDialogue;
