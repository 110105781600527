import React from "react";
import CustomButton from "../CustomButton";
import WalletBallance from "../WalletBallance";
import CustomInput from "../CustomInput";
import { useStore } from "../../Helpers/store";
import { _recharge } from "../../Helpers/api.service";
import {
  toEnDigit,
  toArabicDigit,
  validatePhoneNumber,
} from "../../Helpers/helperFunctions";
import PhoneInput from "../PhoneInput";
import ModalState from "../ModalState";

type Props = {};

type State = "same" | "different" | "undetermined";

const companiesInitialState = [
  {
    logo: "images/orange.png",
    name: "اورنج",
    value: "orange",
    selected: false,
  },
  {
    logo: "images/vodafone.png",
    name: "فودافون",
    value: "vodafone",
    selected: false,
  },
  {
    logo: "images/etisalat.png",
    name: "اتصالات",
    value: "etisalat",
    selected: false,
  },
  {
    logo: "images/we.png",
    name: "وي",
    value: "we",
    selected: false,
  },
];

const Recharge = (props: Props) => {
  const [companies, setCompanies] = React.useState(companiesInitialState);
  const [state, setState] = React.useState<State>("undetermined");

  const [selectedCompany, setSelectedCompany] = React.useState<
    "orange" | "vodafone" | "etisalat" | "we"
  >();
  const [number, setNumber] = React.useState<string>();
  const [invalidNumber, setInvalidNumber] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>();
  const [modalState, setModalState] = React.useState<
    "success" | "error" | "warning"
  >();

  const balance = useStore((state) => state.Balance);

  const handleCompanyClick = (index: number) => {
    setSelectedCompany(
      companies[index].value as "orange" | "vodafone" | "etisalat" | "we"
    );
    setCompanies((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            selected: true,
          };
        } else {
          return {
            ...item,
            selected: false,
          };
        }
      });
    });
  };

  const recharge = async () => {
    const res = await _recharge({
      ChargingMobileNo: toEnDigit(number),
      Operator: selectedCompany,
      SelectedValue: toEnDigit(value),
    });

    if (res.Result) {
      setModalState("success");
    } else {
      setModalState("error");
    }
  };

  if (modalState === "success") {
    return <ModalState state={"success"} title="تمت العملية بنجاح" />;
  }
  if (modalState === "error") {
    return <ModalState state={"error"} title="حدث خطأ ما" />;
  }

  return (
    <div className="w-full">
      <p className="text-[26px] font-[400] absolute top-[15px]">
        شحن من الرصيد
      </p>
      <div className="mt-[20px]">
        {state === "undetermined" ? (
          <div className="flex flex-col gap-[10px]">
            <div className="flex">
              <CustomButton onClick={() => setState("same")}>
                نفس الرقم
              </CustomButton>
            </div>
            <div className="flex">
              <CustomButton onClick={() => setState("different")}>
                رقم آخر
              </CustomButton>
            </div>
          </div>
        ) : (
          <div>
            <WalletBallance />
            <p className="text-[14px] font-[500] mb-[5px]">اختر الشبكة</p>
            <div className="flex justify-between">
              {companies.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleCompanyClick(index)}
                    className={`flex flex-col items-center w-[73px] pt-[8px] pb-[13px] ${
                      item.selected ? "border-red-100" : "border-gray-100"
                    }  ${
                      item.selected ? "border-[2px]" : "border-[1px]"
                    } rounded-[10px]`}
                  >
                    <img src={item.logo} alt={item.name} className="h-[42px]" />
                    <p className="text-[14px] font-[500] mt-[10px]">
                      {item.name}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col mt-[24px]">
              <div className="flex flex-col gap-[14px] mb-[14px]">
                {state === "different" ? (
                  <>
                    <PhoneInput
                      title="رقم الهاتف"
                      placeholder="0100000000"
                      fullSize
                      value={number}
                      onChange={(value) => {
                        setNumber(value);

                        setInvalidNumber(
                          value.length >= 11
                            ? !validatePhoneNumber(value.trim())
                            : false
                        );
                      }}
                    />
                    {invalidNumber && (
                      <p className="-mt-2 text-xs text-red-100">
                        رقم الهاتف غير صحيح
                      </p>
                    )}
                  </>
                ) : null}

                <CustomInput
                  title="أدخل المبلغ الذي تريد شحنه"
                  subTitle="الحد الأدنى للشحن ٩ جم"
                  placeholder="0000"
                  fullSize
                  value={toArabicDigit(value) || ""}
                  onChange={(value) => {
                    setValue(toEnDigit(value));
                  }}
                />
                {value && +value < 9 && (
                  <p className="-mt-2 text-xs text-red-100">
                    الحد الأدنى للشحن ٩ جم
                  </p>
                )}
              </div>

              {/* Submit */}
              <div className="flex">
                <CustomButton
                  disabled={
                    !selectedCompany ||
                    !value ||
                    (state === "different"
                      ? !number || invalidNumber
                      : false) ||
                    +value < 9 ||
                    +value > +balance ||
                    isNaN(+value)
                  }
                  onClick={() => {
                    recharge();
                  }}
                >
                  تأكيد
                </CustomButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Recharge;
