import React from "react";

type Props = {
  label: string;
  onChange: (val: string) => void;
  checked: string;
  mt: number;
};

export default function CheckBox({ label, onChange, checked, mt }: Props) {
  return (
    <label className={`inline-flex items-center`} style={{ marginTop: mt }}>
      <input
        type="checkbox"
        checked={checked === "1"}
        onChange={() => onChange(checked === "1" ? "0" : "1")}
        className="form-checkbox h-[19px] w-[19px]"
      />
      <span className="ml-3 text-black-400 text-[14px]">{label}</span>
    </label>
  );
}
