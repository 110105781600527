import React from "react";
import { toArabicDigit } from "../../Helpers/helperFunctions";
type Props = {
  otp: number[];
  handleOtp: (otp: string, index: number) => void;
  handlePaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
};

export default function OTPInputs({ otp, handleOtp, handlePaste }: Props) {
  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const input = event.target;
    const value = input.value;

    handleOtp(value, index);

    if (value && index < 3) {
      const nextInput = document.querySelector(`#input-${index + 1}`);
      if (nextInput) {
        // @ts-ignore
        nextInput.focus();
      }
    }

    if (value === "" && index > 0) {
      const previousInput = document.querySelector(`#input-${index - 1}`);
      if (previousInput) {
        // @ts-ignore
        previousInput.focus();
      }
    }
  };

  return (
    <div className="flex justify-center gap-2.5" dir="ltr">
      {[0, 1, 2, 3].map((index) => {
        return (
          <input
            key={index}
            id={`input-${index}`}
            value={
              otp?.length > 0
                ? toArabicDigit(
                    otp[index] !== undefined ? otp[index].toString() : ""
                  )
                : ""
            }
            className="w-8 h-12 border-1 border-gray-500 rounded-[8px] outline-none px-2 text-center text-[20px] font-semibold"
            style={{ border: "1px solid #9E9E9E" }}
            type="tel"
            maxLength={1}
            autoComplete="off"
            onChange={(e) => handleInput(e, index)}
            onPaste={handlePaste}
          />
        );
      })}
    </div>
  );
}
