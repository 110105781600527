import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

type Props = {};

const CustomSwiper = (props: Props) => {
  return (
    <Swiper spaceBetween={20} slidesPerView={1}>
      {Array(1)
        .fill("_")
        .map((item, index) => (
          <SwiperSlide key={index}>
            <div className="h-[auto] w-full bg-red-100 rounded-[20px]">
              <img
                src="images/slider_image_1.png"
                alt=""
                className="w-full h-full"
              />
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default CustomSwiper;
