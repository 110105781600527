import React from "react";
import { toArabicDigit } from "../../Helpers/helperFunctions";

type Props = {
  title?: string;
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  errorMessage?: string;
  disabled?: boolean;
  fullSize?: boolean;
  name?: string;
  onBlur?: () => void;
};

export default function PhoneInput({
  title,
  value,
  onChange,
  errorMessage,
  disabled,
  fullSize,
  name,
  onBlur,
  placeholder,
}: Props) {
  return (
    <div className={`flex flex-col ${fullSize ? "w-full" : "w-[49%]"}`}>
      <span className="text-[15px] font-semibold mb-2">{title || ""}</span>
      <input
        className="bg-lightBlue-100 rounded-[5px] min-h-[3.125rem] outline-none px-5 text-xl"
        dir="rtl"
        value={toArabicDigit(value)}
        onChange={(e) => onChange && onChange(e.target.value.trim())}
        type={"tel"}
        onBlur={onBlur}
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        disabled={disabled}
        name={name}
        placeholder={placeholder}
      />
      <span className="text-[12px] font-semibold text-red-200  mt-2">
        {errorMessage || ""}
      </span>
    </div>
  );
}
