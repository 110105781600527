import React from "react";
import { MerchantNames, companies } from "../../Helpers/api.service";
import CustomButton from "../CustomButton";

type Props = {
  onMerchantSelected: (merchant: MerchantNames) => void;
};

const SelectMerchant = (props: Props) => {
  const [selectedMerchant, setSelectedMerchant] =
    React.useState<MerchantNames>(null);

  const handleSubmit = () => {
    props.onMerchantSelected(selectedMerchant);
  };

  return (
    <div className="flex flex-col gap-[25px]">
      <div className="flex flex-col items-center p-[16px]">
        <p className="text-red-100 font-[600] mb-[15px]">اختر متجر</p>
        <div className="flex  gap-[10px]">
          {companies.map((item, index) => {
            const isSelected = selectedMerchant === item.id;
            return (
              <div
                key={index}
                className={`bg-white rounded-[10px] flex flex-col justify-center items-center border-[1px] p-[10px] ${
                  isSelected ? "border-red-100" : "border-gray-500"
                } ${isSelected ? "border-[2px]" : "border-[1px]"}
                }`}
                onClick={() => setSelectedMerchant(item.id)}
              >
                <img
                  src={item.logo}
                  alt=""
                  className="w-[50px] h-[50px] object-contain"
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex">
        <CustomButton onClick={handleSubmit} disabled={!selectedMerchant}>
          تأكيد
        </CustomButton>
      </div>
    </div>
  );
};

export default SelectMerchant;
