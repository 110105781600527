import React from "react";
import Close from "../../Assets/close.png";

type Props = {
  title?: string;
  subTitle?: string;
  value?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  errorBorder?: boolean;
  type?: "email" | "password" | "text" | "number" | "tel";
  disabled?: boolean;
  fullSize?: boolean;
  name?: string;
  placeholder?: string;
  onBlur?: () => void;
  withRemoveIcon?: boolean;
  onRemoveIconClick?: () => void;
};

export default function CustomInput({
  title,
  subTitle,
  value,
  onChange,
  errorMessage,
  type,
  disabled,
  fullSize,
  name,
  placeholder,
  onBlur,
  errorBorder,
  withRemoveIcon,
  onRemoveIconClick,
}: Props) {
  return (
    <div
      className={`flex flex-col ${fullSize ? "w-full" : "w-[49%] "} relative`}
    >
      <div className="flex mb-2 max-[430px]:flex-col">
        <span className="text-[15px] font-semibold">{title || ""}</span>
        {subTitle && subTitle !== "" && (
          <span className="text-[15px] font-normal text-gray-100 mx-[5px] max-[430px]:mx-0">
            ({subTitle || ""})
          </span>
        )}
      </div>
      <input
        className={`bg-lightBlue-100 rounded-[5px] min-h-[3.125rem] outline-none px-5 text-xl ${
          errorBorder ? "border-red-100 border-[1px] border-solid" : ""
        } `}
        dir="rtl"
        value={value}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value.trim())}
        type={type}
        autoComplete="off"
        disabled={disabled}
        name={name}
        placeholder={placeholder}
      />

      {withRemoveIcon ? (
        <div
          onClick={onRemoveIconClick}
          className="absolute top-[45px] left-[10px] "
        >
          <img src={Close} alt="" />
        </div>
      ) : null}
      <span className="text-[12px] font-semibold text-red-200  mt-2">
        {errorMessage || ""}
      </span>
    </div>
  );
}
