import React from "react";
import { useStore } from "../../Helpers/store";
import { toArabicDigit } from "../../Helpers/helperFunctions";

const WalletBallance = () => {
  const balance = useStore((state) => state.Balance);
  const currency = useStore((state) => state.Currency);

  return (
    <div className="gap-[20px]">
      <div className="text-right">
        <p className="text-[16px]">المحفظة</p>
        <div className="flex items-center gap-2">
          <p className="text-[46px] font-bold">{toArabicDigit(balance)}</p>
          <p className="text-[18px] font-bold ">{currency}</p>
        </div>
      </div>
    </div>
  );
};

export default WalletBallance;
