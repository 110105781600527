import React from "react";
import TransactionItem from "../TransactionItem";
import { useStore } from "../../Helpers/store";

type Props = {};

const TransactionList = (props: Props) => {
  const loggedIn = useStore((state) => state.loggedIn);
  const History = useStore((state) => state.History);

  return !loggedIn || !History || History.length === 0 ? null : (
    <div className="flex flex-col text-right mt-[25px] px-[16px]">
      <p className="text-[14px] font-[700]">تاريخ المعاملات</p>
      <div className="flex flex-col bg-gray-300 rounded-[5px]">
        {History.map((item, index, array) => (
          <React.Fragment key={index}>
            <TransactionItem item={item} />

            {/* Add a separator for all but the last item */}
            {index < array.length - 1 && (
              <div className="w-full h-[1px] bg-gray-400" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default TransactionList;
