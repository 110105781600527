import React from "react";
import CustomButton from "../CustomButton";
import WalletBallance from "../WalletBallance";
import SelectPrice from "../SelectPrice";
import CustomInput from "../CustomInput";
import RedeemSuccess from "../RedeemSuccess";
import { MerchantNames, _redeem } from "../../Helpers/api.service";
import { useStore } from "../../Helpers/store";
import { toArabicDigit, toEnDigit } from "../../Helpers/helperFunctions";
import ModalState from "../ModalState";
import SelectMerchant from "../SelectMerchant";

type Props = {};

const Redeem = (props: Props) => {
  const [selectedMerchant, setSelectedMerchant] =
    React.useState<MerchantNames | null>(null);
  const [selectedValue, setSelectedValue] = React.useState<string>();
  const [modalState, setModalState] = React.useState<"success" | "error" | "">(
    ""
  );
  const [code, setCode] = React.useState("");
  const balance = useStore((state) => state.Balance);

  const renderSelectedMerchantIcon = () => {
    return (
      <img
        src={`images/${selectedMerchant}.png`}
        alt=""
        className="w-[50px] h-[50px] mb-[25px] object-contain"
      />
    );
  };

  if (modalState === "success") return <RedeemSuccess value={code || ""} />;
  if (modalState === "error")
    return <ModalState state={"error"} title="حدث خطأ ما" />;

  const handleRedeemCode = async () => {
    if (!selectedValue) return;
    const data = await _redeem({
      Merchant: selectedMerchant,
      SelectedValue: String(selectedValue),
    });

    if (data.Result) {
      setCode(data.VoucherNo);
      setModalState("success");
    } else {
      setModalState("error");
    }
  };

  return (
    <div className="w-full">
      <p className="text-[26px] font-[400] absolute top-[15px]">إستبدال نقاط</p>
      <div className="mt-[20px]">
        {selectedMerchant === null ? (
          <SelectMerchant
            onMerchantSelected={(merchant) => {
              setSelectedMerchant(merchant);
            }}
          />
        ) : (
          <div>
            <WalletBallance />
            {renderSelectedMerchantIcon()}
            <p className="text-[14px] font-[500] mb-[5px]">
              أختر المبلغ الذي تريد استرداده
            </p>

            {selectedMerchant !== "kazyon" ? (
              <CustomInput
                placeholder="0000"
                fullSize
                value={toArabicDigit(selectedValue) || ""}
                onChange={(val) => setSelectedValue(toEnDigit(val))}
              />
            ) : (
              // @ts-ignore
              <SelectPrice onChange={(val) => setSelectedValue(val)} />
            )}

            <div className="flex flex-col mt-[24px]">
              {/* Submit */}
              <div className="flex">
                <CustomButton
                  onClick={handleRedeemCode}
                  disabled={
                    !selectedValue ||
                    +selectedValue > +balance ||
                    isNaN(+selectedValue)
                  }
                >
                  تأكيد
                </CustomButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Redeem;
