import React from "react";
import CustomDialogue from "../CustomDialogue";
import { ModalConfig } from "../../hooks/useModal";
import Recharge from "../Recharge";

type Props = {
  modalConfig: ModalConfig;
};

const RechargeModal = ({ modalConfig }: Props) => {
  return (
    <CustomDialogue modalConfig={modalConfig}>
      <Recharge />
    </CustomDialogue>
  );
};

export default RechargeModal;
