import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./Screens/Home";
import Signup from "./Screens/Signup";
import Login from "./Screens/Login";
import OTP from "./Screens/OTP";
import Terms from "./Screens/Terms";

const App = () => {
  return (
    <div dir="rtl" className="flex justify-center items-center ">
      <div className={"lg:w-[50vw] md:w-[50vw] w-[100vw]"}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otp/:page?" element={<OTP />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
