import React from "react";

type Props = {
  title?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  errorMessage?: string;
  disabled?: boolean;
  fullSize?: boolean;
  options?: {
    title: string;
    value: string;
  }[];
};

export default function Select({
  title,
  value,
  onChange,
  errorMessage,
  disabled,
  fullSize,
  options,
  onBlur,
}: Props) {
  return (
    <div className={`flex flex-col ${fullSize ? "w-full" : "w-[49%]"}`}>
      <span className="text-[15px] font-semibold mb-2">{title || ""}</span>
      <div className="bg-lightBlue-100 rounded-[5px] w-full px-5">
        <select
          className="w-full bg-lightBlue-100 min-h-[3.125rem] outline-none  custom-select"
          dir="rtl"
          value={value}
          onBlur={onBlur}
          onChange={(e) => onChange && onChange(e.target.value)}
          disabled={disabled}
        >
          <option value=""></option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
      <span className="text-[12px] font-semibold text-red-200 mt-2">
        {errorMessage || ""}
      </span>
    </div>
  );
}
