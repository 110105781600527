import { useCallback, useState } from "react";

export type ModalConfig = {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
};

const useModal = (): ModalConfig => {
  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    openModal,
    closeModal,
  };
};

export default useModal;
