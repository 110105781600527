import React from "react";

type Props = {
  item: {
    Amount: string;
    Description: string;
    Logo: string;
    TransDate: string;
    TransType: string;
  };
};

const TransactionItem = ({ item }: Props) => {
  return (
    <div className="flex justify-between px-[12px] py-[20px]">
      {/* right */}
      <div className="flex gap-[10px]">
        <div className="flex justify-center items-center w-[55px] h-[55px] rounded-[10px] bg-white overflow-hidden">
          <img
            src={item.Logo || "images/banner.jpg"}
            className="h-[90%]"
            alt=""
          />
        </div>

        <div className="flex flex-col">
          <p className="font-[700]">{item.Description}</p>
          <p className="text-gray-100">{item.TransDate}</p>
          <p className="font-[700]">{item.Amount}</p>
        </div>
      </div>
      {/* left */}
      <div>
        <p className="text-gray-200 font-[700]">{item.TransType}</p>
      </div>
    </div>
  );
};

export default TransactionItem;
