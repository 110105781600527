import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface StoreState {
  isLoading: boolean;

  loggedIn: boolean;
  MobileNo: string;
  Balance: string;
  Currency: string;
  Token: string;
  AddCodeAttempts: number;
  History: {
    Amount: string;
    Description: string;
    Logo: string;
    TransDate: string;
    TransType: string;
  }[];

  SelectedMerchant: string;
  MerchantBranches: {
    merchant: string;
    branches: {
      BranchAddress: string;
      BranchId: string;
      BranchName: string;
      CityName: string;
      Latitude: string;
      LogoImage: string;
      Longitude: string;
    }[];
  }[];

  SetLoading: (isLoading: boolean) => void;

  Login: (data: {
    MobileNo: string;
    Balance: string;
    Token: string;
    Currency: string;
  }) => void;
  Logout: () => void;
  UpdateBalance: (data: { Balance: string }) => void;
  SetMobileNo: (MobileNo: string) => void;
  IncrementAddCodeAttempts: () => void;
  ResetAddCodeAttempts: () => void;

  SetSelectedMerchant: (merchant: string) => void;
  SetMerchantBranches: (data: {
    merchant: string;
    branches: {
      BranchAddress: string;
      BranchId: string;
      BranchName: string;
      CityName: string;
      Latitude: string;
      LogoImage: string;
      Longitude: string;
    }[];
  }) => void;

  UpdateHistory: (
    data: {
      Amount: string;
      Description: string;
      Logo: string;
      TransDate: string;
      TransType: string;
    }[]
  ) => void;
}

export const useStore = create<StoreState>()(
  devtools(
    persist(
      (set) => ({
        isLoading: false,

        loggedIn: false,
        MobileNo: "",
        Balance: "0",
        Token: "",
        Currency: "ج.م",
        AddCodeAttempts: 0,
        History: null,

        SelectedMerchant: null,
        MerchantBranches: [],

        SetLoading: (isLoading) => set({ isLoading }),

        Login: ({ MobileNo, Balance, Token, Currency }) =>
          set({ loggedIn: true, MobileNo, Balance, Token, Currency }),
        Logout: () =>
          set({
            loggedIn: false,
            MobileNo: "",
            Balance: "0",
            Token: "",
            Currency: "ج.م",
            AddCodeAttempts: 0,
          }),
        UpdateBalance: ({ Balance }) => set({ Balance }),
        SetMobileNo: (MobileNo) => set({ MobileNo }),
        IncrementAddCodeAttempts: () =>
          set((state) => ({ AddCodeAttempts: state.AddCodeAttempts + 1 })),
        ResetAddCodeAttempts: () => set({ AddCodeAttempts: 0 }),

        SetSelectedMerchant: (merchant) => set({ SelectedMerchant: merchant }),
        SetMerchantBranches: ({ merchant, branches }) =>
          set((state) => ({
            MerchantBranches: [
              ...state.MerchantBranches.filter(
                (item) => item.merchant !== merchant
              ),
              { merchant, branches },
            ],
          })),

        UpdateHistory: (History) => set({ History }),
      }),
      { name: "loynova-storage" }
    )
  )
);
