import CustomButton from "../CustomButton";
import WalletBallance from "../WalletBallance";

type Props = {
  onRedeem?: () => void;
  onAddCode?: () => void;
  onRecharge?: () => void;
};

const HomeLoggedInButtons = (props: Props) => {
  return (
    <div className="flex flex-col gap-[10px] pt-[19px] pb-[14px] px-[14px]">
      <WalletBallance />
      <div className="flex items-center gap-[20px]">
        <CustomButton onClick={props?.onAddCode}>أضف الكود</CustomButton>
        <CustomButton onClick={props?.onRedeem}>إستبدال نقاط</CustomButton>
      </div>
      <div className="flex">
        <CustomButton onClick={props?.onRecharge}>شحن من الرصيد</CustomButton>
      </div>
    </div>
  );
};

export default HomeLoggedInButtons;
